/* eslint-disable react-hooks/exhaustive-deps */
import { lazy } from 'react';
import { Routes, Route } from 'react-router-dom';

const lazyWithRetry = (componentImport) => {
    const lsKey = 'page-has-been-force-refreshed-pplfirst';
    return lazy(async () => {
        const pageHasAlreadyBeenForceRefreshed = JSON.parse(
            window.sessionStorage.getItem(lsKey) || 'false'
        );

        try {
            const component = await componentImport();
            window.sessionStorage.setItem(lsKey, 'false');
            return component;
        } catch (error) {
            if (!pageHasAlreadyBeenForceRefreshed) {
                window.sessionStorage.setItem(lsKey, 'true');
                return window.location.reload();
            }
            throw error;
        }
    });
}

const DefaultLayout = lazyWithRetry(() => import('./layouts/Default'));
const Login = lazyWithRetry(() => import('./pages/Login'));
const ResetPassword = lazyWithRetry(() => import('./pages/ResetPassword'));
const Dashboard = lazyWithRetry(() => import('./pages/dashboard/Dashboard'));
const Roles = lazyWithRetry(() => import('./pages/Roles'));
const Users = lazyWithRetry(() => import('./pages/Users'));
const ChangePassword = lazyWithRetry(() => import('./pages/ChangePassword'));
const Profile = lazyWithRetry(() => import('./pages/Profile'));

const Clients = lazyWithRetry(() => import('./pages/client/Clients'));

const NotFound404 = lazyWithRetry(() => import('./pages/NotFound404'));

export default function AppRoutes() {
    return (
        <Routes>
            <Route path="/" element={<Login />} />
            <Route path="/reset-password" element={<ResetPassword />} />

            <Route path="/:slug/" element={<DefaultLayout />}>
                <Route index element={<NotFound404 />} />
                <Route path="dashboard" element={<Dashboard />} />

                <Route path="roles" element={<Roles />} />
                <Route path="users" element={<Users key="0" pageType="Users" />} />
                <Route path="manager-accounts" element={<Users key="1" pageType="Manager Accounts" />} />
                <Route path="user-accounts" element={<Users key="2" pageType="User Accounts" />} />
                <Route path="change-password" element={<ChangePassword />} />
                <Route path="profile" element={<Profile />} />
                <Route path="clients" element={<Clients />} />

                <Route path="*" element={<NotFound404 />} />
            </Route>
        </Routes>
    );
}