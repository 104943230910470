import React from "react";
import { BrowserRouter } from "react-router-dom";
import AppRoutes from "./AppRoutes";

const ThemeLight = React.lazy(() => import('./ThemeLight'));
const ThemeDark = React.lazy(() => import('./ThemeDark'));

const ThemeSelector = () => {
	const CHOSEN_THEME = window.localStorage.getItem('TYPE_OF_THEME') || 'light';
	return (
		<>
			{CHOSEN_THEME === 'dark' ? (
				<ThemeDark />
			) : (
				<ThemeLight />
			)}
		</>
	)
}

function App() {
	return (
		<React.Suspense fallback={<div style={{ padding: 20, textAlign: 'center', color: '#ccc' }}>Loading...</div>}>
			<ThemeSelector></ThemeSelector>
			<React.Fragment>
				<BrowserRouter basename={"/"}>
					<AppRoutes></AppRoutes>
				</BrowserRouter>
			</React.Fragment>
		</React.Suspense>
	);
}

export default App;